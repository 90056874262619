import React, { useState } from "react";

export interface CarouselItems {
  id: string;
  title: string;
  description: string;
  image: string;
}

interface CarouselProps {
  items: CarouselItems[];
}

const CenteredCarousel: React.FC<CarouselProps> = ({ items }) => {
  const [centerIndex, setCenterIndex] = useState(1);

  const handleNext = () => {
    setCenterIndex((prev) => (prev === items.length - 1 ? 0 : prev + 1));
  };

  const handlePrevious = () => {
    setCenterIndex((prev) => (prev === 0 ? items.length - 1 : prev - 1));
  };

  const getVisibleItems = () => {
    const result = [];
    for (let i = -1; i <= 1; i++) {
      let index = centerIndex + i;

      if (index < 0) index = items.length - 1;
      if (index >= items.length) index = 0;

      result.push({
        item: items[index],
        position: i,
      });
    }
    return result;
  };

  return (
    <div className="relative mt-16 hidden overflow-hidden">
      {getVisibleItems().map((item, index) => (
        <div
          key={item.item.id}
          className={`m-1 flex h-[567px] w-[850px] shrink-0 rounded-[40px] transition-all duration-300 ${
            index === 1 ? "relative z-10 scale-100" : "scale-95 opacity-50"
          }`}
        >
          <img
            src={item.item.image}
            alt={item.item.title}
            className="rounded-[40px] object-cover"
          />
          {index === 1 && (
            <>
              <button
                onClick={handlePrevious}
                className="absolute -left-14 top-1/2 z-20 -translate-y-1/2 rounded-full bg-white p-2 shadow-lg transition-all hover:bg-gray-100"
              >
                <img
                  src="/assets/icons/circle-arrow-right.svg"
                  alt="circle arrow"
                />
              </button>
              <button
                onClick={handleNext}
                className="absolute -right-14 top-1/2 z-20 -translate-y-1/2 rounded-full bg-white p-2 shadow-lg transition-all hover:bg-gray-100"
              >
                <img
                  src="/assets/icons/circle-arrow-left.svg"
                  alt="circle arrow"
                />
              </button>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default CenteredCarousel;
