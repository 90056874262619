import { JwtToken } from '@/features/onboarding/domain/models/signInResponse';
import { UserModel } from 'common/models/userModel';
import  STORAGE_KEYS, { clearAllStorageItems, clearStorageItems } from 'common/utils/localStorage';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface AuthState {
  isAuthenticated: boolean;
  token: JwtToken | null;
  signIn: (token: JwtToken, user:UserModel | null) => void;
  refreshToken: (token: JwtToken) => void;
  signOut: () => void;
  user: UserModel | null;
}

const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      isAuthenticated: false,
      token: null,
      signIn: (token: JwtToken, user: UserModel | null) => {
        clearStorageItems([STORAGE_KEYS.DONATE_FORM_DATA])
        
        return set({ isAuthenticated: true, token, user });
      },
      refreshToken: (tokens: JwtToken) => {
        return set((state) => ({ 
          ...state,
          token: { 
            ...state.token ?? {accessToken: '', refreshToken: ''},  
            accessToken: tokens.accessToken
          }
        }));
      },
      signOut: () => {
        clearAllStorageItems()
        return set({ isAuthenticated: false, token: null, user:null});
      },
      user: null,
    }),
    {
      name: 'auth-storage', 
    }
  )
);

export default useAuthStore;

const getSessionUser = () => {
  const { user } = useAuthStore.getState();
  return user;
};

export { getSessionUser };



