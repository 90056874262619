import Routes from "router/routes";
import useDiceBear from "common/hooks/useDiceBear";
import { useNavigate } from "react-router-dom";

const JoinBanner = () => {
  const avatar = useDiceBear({
    seed: "Samuel",
  });

  const navigate = useNavigate();

  const StartFundraisingButton = (
    <button
      onClick={() => navigate(Routes.CREATE_CAMPAIGN)}
      className="flex h-[56px] w-[245px] transform items-center justify-center rounded-[60px] bg-[#022928] text-white transition-transform hover:scale-105 active:scale-95 lg:h-[72px]"
    >
      <span className="mr-2">Start Fundraising</span>
      <img className="h-5 w-5" src="/assets/icons/Arrow.svg" alt="arrow" />
    </button>
  );
  return (
    <div className="z-999 absolute bottom-[-320px] left-0 right-0 mt-[200px]">
      <div className="mx-auto h-[468px] w-full max-w-[358px] rounded-3xl bg-[#FDF8F7] p-8 shadow-lg md:max-w-[768px] lg:max-w-[1290px]">
        <div className="flex w-full flex-col items-start justify-between gap-8 md:flex-row lg:items-center">
          {/* Left side - Title */}
          <h2 className="lg:text-5xl leading-tight text-left font-dm-sans text-[42px] leading-[42px] tracking-[-0.03em] text-[#131313] lg:text-[100px] lg:leading-[100px] lg:text-[#022928]">
            Join Giv
            <br />
            Today
          </h2>

          {/* Right side - Content */}
          <div className="space-y-6">
            <h3 className="lg:text-2xl leading:[30px] text-left font-inter text-[24px] font-medium tracking-[-0.01em] text-[#131313] lg:text-[30px] lg:leading-[37.5px] lg:tracking-[-0.02em] lg:text-[#1B2A27]">
              Happy fundraisers have reached their
              <br />
              fundraising targets.
            </h3>

            {/* Rating Section */}
            <div className="flex flex-col gap-2 lg:flex-row lg:items-center lg:gap-8">
              {/* Rating and Review Container */}
              <div className="flex items-center justify-between lg:block lg:space-y-1">
                {/* Stars and Rating */}
                <div className="flex items-center">
                  <span className="text-2xl font-semibold">4.8</span>
                  {[1, 2, 3, 4].map((_, i) => (
                    <img
                      key={i}
                      src="/assets/icons/star.svg"
                      alt="Star"
                      className="h-5 w-5"
                    />
                  ))}
                  <img
                    src="/assets/icons/star.svg"
                    alt="Star"
                    className="h-5 w-5 fill-gray-200 text-gray-200"
                  />
                </div>

                {/* Review Count */}
                <div className="text-sm text-gray-600">
                  (1.5k Positive review)
                </div>
              </div>

              {/* Avatar Stack */}
              <div className="flex -space-x-3">
                {[1, 2, 3, 4].map((_, i) => (
                  <div
                    key={i}
                    className="h-10 w-10 overflow-hidden rounded-full border-2 border-white"
                  >
                    <img
                      src={avatar}
                      alt={`User avatar ${i + 1}`}
                      className="h-full w-full object-cover"
                    />
                  </div>
                ))}
              </div>
            </div>

            {/* CTA Button */}
            {StartFundraisingButton}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinBanner;
